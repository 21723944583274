body {
    background: #22252a;
    color: #919397;
}

nav,
footer.footer {
    background: #22252a !important;
    background: none !important;
}

div.main {
    background: #22252a !important;
    /* overflow-y: scroll;; */
}

.sidebar,
.sidebar-content {
    background: #22252a; 
}

div.simplebar-content {
    background: #22252a !important;
}

.loading {
    background: url('../img/animate/loading.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#loginScr-loading {
    background: rgba(0,0,0,0.75) url('../img/animate/loading.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150px;
    background-blend-mode: luminosity;
    position: fixed;
     width: 100vw;
      height: 100vh;
      display: none;
}
#loginScr-Main.onLoad > main{
    filter:blur(15px) brightness(50%);
}
#loginScr-Main.onLoad > #loginScr-loading{
    display: block;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active > .sidebar-link {
    color: #ecf0f3;
    background: #2d3036;
    border-radius: 0.5em;
}

.sidebar-link,
a.sidebar-link {
    color: #adb0b3;
}

.text-custom,
.text-custom::before,
.text-custom::after {
    color: #919397;
}

.text-custom-light,
.text-custom-light::before,
.text-custom-light::after {
    color: #a3a6ac;
}

.text-custom-important,
.text-custom-important::before,
.text-custom-important::after {
    color: #ecf0f3 !important;
}


.text-custom-dark,
.text-custom-dark::before,
.text-custom-dark::after {
    color: hsl(206, 7%, 40%) !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #919397;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #babcc0;
}

.nav-flag,
.nav-icon {
    color: #919397;
}

.nav-flag.active,
.nav-flag:hover,
.nav-icon.active,
.nav-icon:hover {
    color: #babcc0;
}

.custom-menu-icon {
    color: #babcc0;
}

.custom-menu-icon:hover {
    color: #babcc0;
}

.card-header {
    background: transparent;
}

.card {
    background: #2d3036;
    box-shadow: 0px 5px 15px rgba(30, 33, 36, 0.5);
    transition: all 0.25s ease-out,background 0.15s ease-out;
}

.card-hoverable:hover {
    background: #22252a;
    margin-top: -10px;
    margin-bottom: 10px;
    cursor: pointer;
    /* box-shadow: 0px 15px 15px rgba(0,0,0,0.1) !important; */
}

.status-icon {
    width: 15px;
    vertical-align: top;
}

.table {
    color: #adb5bd;
}

.table a{
    color: #adb5bd;
}

.table thead th {
    border-bottom: 2px solid #3b4044;
}
.table thead.top-border th {
    border-top: 1px solid #3b4044 !important;
}

.table td,
.table th {
    border-bottom: 1px solid #24272b;
    border-top: 0px solid #24272b;
}

.table-hover tbody tr:hover ,
.table-hover tbody tr:hover a:not(.cancel-text){
    color: #dfe3e5;
    background: hsl(218, 8%, 24%);
}

.table.table-striped td,
.table.table-striped th {
    border-bottom: 0px solid #24272b;
    padding: 1rem 0.75rem;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #363a3f;
}

.legend-test {
    background: rgba(17, 190, 190, 0.7)
}

.card > .dataTables_wrapper .table.dataTable td:first-child,
.card > .dataTables_wrapper .table.dataTable th:first-child,
.card > .table-responsive-lg .table td:first-child,
.card > .table-responsive-lg .table th:first-child,
.card > .table-responsive-md .table td:first-child,
.card > .table-responsive-md .table th:first-child,
.card > .table-responsive-sm .table td:first-child,
.card > .table-responsive-sm .table th:first-child,
.card > .table-responsive-xl .table td:first-child,
.card > .table-responsive-xl .table th:first-child,
.card > .table-responsive .table td:first-child,
.card > .table-responsive .table th:first-child,
.card > .table td:first-child,
.card > .table th:first-child {
    padding-left: 0.5rem;
}

.card > .dataTables_wrapper .table.dataTable td:last-child,
.card > .dataTables_wrapper .table.dataTable th:last-child,
.card > .table-responsive-lg .table td:last-child,
.card > .table-responsive-lg .table th:last-child,
.card > .table-responsive-md .table td:last-child,
.card > .table-responsive-md .table th:last-child,
.card > .table-responsive-sm .table td:last-child,
.card > .table-responsive-sm .table th:last-child,
.card > .table-responsive-xl .table td:last-child,
.card > .table-responsive-xl .table th:last-child,
.card > .table-responsive .table td:last-child,
.card > .table-responsive .table th:last-child,
.card > .table td:last-child,
.card > .table th:last-child {
    padding-left: 0.5rem;
}

.dropdown-menu {
    color: #adb0b3;
    background: #2a2e33 !important;
    border: 1px solid #3b4044;
}

.dropdown-menu-header {
    border-bottom: 1px solid #3b4044;
}

.list-group-item {
    background: #2a2e33 !important;
    border: 1px solid #3b4044;
}

.dropdown-divider {
    border-top: 1px solid #3b4044;
}

.dropdown-item {
    color: #adb5bd;
    background: transparent;
    transition: all 0.25s ease-out;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #adb5bd;
    background: #22252a;
    filter: brightness(150%);
}

.dropdown-item.active{
    pointer-events: none;
    background: transparent !important;
    color: #6c757d !important;
}



.text-muted-custom {
    color: #adb5bd;
}

.card-icon-custom {
    width: 55px;
    height: 55px;
    padding: 5px 0px;
    color: #548adb;
}

.card-icon-custom.card-icon-custom-bg {
    background: #548adb;
    background: hsl(213, 12%, 25%);
    color: #f8f9fa !important;
    padding: 10px;
}

.card:hover .card-icon-custom.card-icon-custom-bg {
    background: hsl(213, 10%, 35%);
}


.card-icon-custom .feather,
.card-icon-custom svg,
.card-icon-custom img {
    width: 50px;
    margin: auto;
}

.card-icon-custom.card-icon-custom-bg svg,
.card-icon-custom.card-icon-custom-bg img {
    height: 35px;
    width: 35px;
}

.card-icon-custom small {
    border-radius: 5px;
    color: #fff;
    margin-top: 10px;
    display: block;
    background: rgba(255, 255, 255, 0.25);
}

.custom-select {
    color: #adb5bd;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23545a60' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.7rem center/8px 10px;
    border: 1px solid #3b4044;
    margin: 0px 2px;
}

.custom-select:focus {
    border-color: rgb(36, 39, 43);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}

.btn-hover-custom:hover{
    color: #c6cbcf;
    background-color: #548adb;
}

.btn-hover-custom-noBG:hover{
    color: #c6cbcf;
}
.btn-hover-custom-noBG:focus{
    box-shadow: none;
}

.btn-hover-custom-noBG::after{
    display: none;
}

.btn-hover-custom-2:hover{
    background: rgba(84, 138, 219,0.25) ;
    color: #5e8ae4 !important;
}

.custom-select {
    text-align: center !important;
}

.dataTables_filter input {
    background: transparent;
}

.custom-select.form-control,
input[type="search"][value=""]:not(.deviceSel),
input.nameEditor{
    color: #c6cbcf;
    border: 1px solid #5c5f61;
}

.custom-select.form-control,
input[type="search"]:not([value=""]):not(.deviceSel),
input.nameEditor{
    color: #c6cbcf;
    background-color: rgba(0,0,0,0.1) !important;
    border: 1px solid rgba(150,150,150,0.15) !important; 
    box-shadow: 0 0 0 0.1rem rgba(100,100,100,0.1);
}

.custom-select.form-control:focus,
input[type="search"]:focus:not(.deviceSel),
input.nameEditor{
    color: #adb5bd;
    background-color: #3b4044 !important;
    border-color: rgb(36, 39, 43);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}

select{
    color: #adb5bd !important;
    appearance: none;
    background: rgba(0,0,0,0.2) url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath fill='%23545a60' d='M 0.31382423,0.99749869 2.0007842,2.6878787 3.6860152,0.99749869'/%3E%3C/svg%3E") no-repeat right 0.7rem center/12px 12px !important;
    background-position: calc(100% - 7.5px);
    background-size: 15px;
    background-repeat: no-repeat;
    border:solid 1px rgba(175,175,175,0.25) !important;
    outline: 0;
    font-size: 0.85rem;
}

select:focus{
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}

option{
    background: #2f3336 !important;
}

input[type=text]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=text]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.page-link {
    color: #adb5bd;
    background-color: transparent;
    border: 1px solid transparent;
}

.page-link:hover {
    z-index: 2;
    color: #ecf0f3;
    text-decoration: none;
    background-color: rgb(78, 83, 90);
    border-color: rgb(69, 74, 82);
}

.page-item.disabled .page-link {
    color: #adb5bd;
    background-color: transparent;
    border-color: transparent;
    padding: 5px 15px;
    vertical-align: middle;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #548adb;
    border-color: #548adb;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #dee2e6;
}

.mapPin-wrapper {
    border-radius: 7.5px;
    position: absolute !important;
    transform: translate(-50%, -100%);
    font-size: 10px;
    top: -15px;
    left: 50%;
    background: #fff;
    box-shadow: 1.5px 1.5px 10px 0px rgba(0, 0, 0, 0.3);
    z-index: 99999999 !important;
    cursor: default;
}

.mapPin-wrapper::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
    box-shadow: 1.5px 1.5px 10px 0px rgba(0, 0, 0, 0.3);
}

.mapPin-wrapper .close {
    position: absolute;
    top: -4px;
    right: 2px;
    vertical-align: top;
    color: #505050 !important;
    /* background: red; */
    padding: 2.5px;
}

.mapHeader-link:hover{
text-decoration: underline;
cursor: pointer;
}


img.icon {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 75px;
    cursor: pointer;
}

.mapPin {
    border-radius: 7.5px;
    text-align: left;
    padding: 10px 25px 10px 10px;
    position: relative;
    background: #fff;
    overflow: hidden;
    z-index: 98;
}

.mapPin h1,
.mapPin p,
.mapPin a {
    display: block;
}

.mapPin h1 {
    color: #000;
    font-size: 135%;
    font-weight: 500;
    margin: auto;
    margin-bottom: 5px;
    white-space: nowrap;
    /* max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-left: 0px; */
}


.mapPin .map-adr{
    font-size: 125%;
}
.mapPin .map-ts{
    margin-top: 0.3rem;
}

.mapPin p,
.mapPin a{
    color: #323232;
    font-size: 110%;
    margin: auto;
    margin-top: 0.1rem;
    white-space: nowrap;
}

.mapPin strong {
    color: #202020;
}

.mapPin a {
    font-size: 120%;
    margin: auto;
    margin-top: 5px;
    color: #427fed !important;
}

.mapPin a:hover {
    text-decoration: underline;
}


.border.border-custom {
    border: 1px solid #202327 !important;
}

.border-top.border-custom {
    border-top: 1px solid #202327 !important;
}

.border-right.border-custom {
    border-right: 1px solid #202327 !important;
}

.border-bottom.border-custom {
    border-bottom: 1px solid #202327 !important;
}

.border-left.border-custom {
    border-left: 1px solid #202327 !important;
}

#deviceInfoIcon {
    width: 250px;
}

@media (max-width: 576px) {
    #deviceInfoIcon {
        width: 250px;
    }
}

@media (max-width: 1615px) {
    #deviceInfoIcon {
        width: 100%;
        max-width: 250px;
    }
}

#mapInfo {
    height: 680px;
}

@media (max-width: 992px) {
    #mapInfo {
        height: 450px;
    }
}

#loginScr {
    background: url('../img/logo/SnapX_Banner_BG.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
}

#loginScr-Main {
    background: none !important;
}

.overflow-custom{
overflow-y: scroll;
}

.overflow-custom::-webkit-scrollbar{
    width: 7.5px;
    height: 7.5px;
}
.overflow-custom::-webkit-scrollbar-track {
    background: transparent;
}

.overflow-custom::-webkit-scrollbar-thumb {
    background: #5a6066;
    border-radius: 5px;
}

.overflow-custom::-webkit-scrollbar-corner {
    background: transparent;
}

.overflow-custom::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.table-fixed thead,
.table-fixed thead tr{
    display: block;
    width: 100% !important;
}

.table-fixed thead th,
.table-fixed tbody td{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.table-fixed thead,
.table-fixed th,
.table-fixed tbody td{
    display: inline-block;
}

.table-fixed tbody{
      height:275px;overflow-y:auto;
      display: block;
      width: 100% !important;
}

.table-fixed tbody tr{
    display: block;
    width: 100% !important;
}

.table > tbody> tr:last-child td,
.table-fixed> tbody> tr:last-child td{
    border-bottom: solid 1px transparent !important;
}
    
.overflow-custom .table> tbody> tr:last-child td{
    border-bottom: solid 1px transparent !important;
}

.apexcharts-toolbar {
    /* background: rgba(0, 0, 0, 0.25); */
    padding: 3px 10px 5px 10px !important;
    margin-right: 20px;
}

.apexcharts-toolbar div{
    transform: scale(1);
    margin: 0px 1.5px !important;
    color: #a3a6ac;
}
.apexcharts-toolbar div.apexcharts-selected{
    color: #548adb;
}
.apexcharts-menu{
    min-width: 111px !important;
}
.chartLegend-collapse{
    display: none;
}

.custom-chart-toolbar{
    width: 100% !important;
    margin-top: -10px;
    z-index: 2;
    margin-right: 25px;
    display: inline-block;
    width: 65px !important;
    position: absolute;
    right: 0px;
}

.custom-chart-toolbar:hover, 
.custom-chart-toolbar:focus{ 
    background: hsl(217, 11%, 17%);
    filter: brightness(150%);
    padding: 2px 10px;
    margin: -12px 17.5px -2px -10px!important;
    width: 80px !important;
    box-shadow: none;
}

.border-bottom,
.border-top,
.border-left,
.border-right{
    border-color: #3b4044 !important;
}

.border-bottom-dark,
.border-top-dark{
    border-color: hsl(213, 12%, 18%) !important;
}

.border-bottom-light,
.border-top-light{
    border-color: rgba(255,255,255,0.1) !important;
}


input.dateSel{
    color: #adb5bd !important;
    background: rgba(0,0,0,0.2) !important;
    border:solid 1px rgba(175,175,175,0.25) !important;
    outline: none !important;
    font-size: 0.85rem;
}

input.dateSel:focus{
    box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
}

::-webkit-calendar-picker-indicator {
    filter: invert(0.7);
    outline: none !important;
}

.text-muted.active{
    color: #2cbbbb !important;
}

#AIChart-wrapper{
    height: 500px;
}
@media (max-width: 576px) {
    #AIChart-wrapper{
        height: 300px;
    }
}

.AI-sel-group p:not(:first-child){
    border-top:solid 1px #3b4044 !important;
}

.dataSel-wrapper{
    max-height: 545px;
    overflow-y: scroll;
    margin-left: 3.75px;
}@media (max-width: 768px) {
    .dataSel-wrapper{
        max-height: 350px !important;
    }
}


.device-info p:last-child{
    margin-bottom: 0.25rem;
}
.avatar-text{
    background-image: linear-gradient(to top right, #2a7cba , #2dccb5);
    color: #fff;
}

.btn-snapX-login{
    background: #3798d2;
    background-image: linear-gradient(to top right, #2d5f86 , #319687);
    outline: none;
    color:#fff;
    transition: all 0.25s ease;
}

.btn-snapX-login:hover{
    background: #468db6;
    background-image: linear-gradient(to top right, #2a7cba , #2dccb5);
    outline: none;
    color:#fff;
}

.btn-line-login{
    background: #00C300;
    color: #fff;
}

.btn-line-login:hover{
    background: #00E000;
}

.btn-line-login:active{
    background: #00B300;
}

.btn-line-link{
    background: #20B24B;
    color: #fff;
}

.btn-line-login:hover{
    background: #00E000;
}

.btn-line-login:active{
    background: #00B300;
}

.overflow-custom-onHover{
    overflow: hidden;
}

.overflow-custom-onHover:hover,
.search-overflow-custom,
.list-group-item-overflow{
    overflow-y: scroll;
    overflow-x: hidden;
}

.overflow-custom-onHover:hover,
.search-overflow-custom > a,
.list-group-item-overflow > div{
    margin-right: -7px !important;
}

.list-group-item-hoverable:hover,
.dropdown-menu-header-hoverable:hover,
.dropdown-menu-footer-hoverable:hover{
    background: #3b4044 !important;
} 

.dropdown-menu-footer-hoverable:hover{
    color:#f8f9fa;
}

.list-group-item-hoverable span{
    color: #468db6 !important;
} 

.list-group-item-hoverable:hover span{
    color: #3798d2 !important;
} 

.overflow-custom-onHover::-webkit-scrollbar,
.search-overflow-custom::-webkit-scrollbar,
.list-group-item-overflow::-webkit-scrollbar{
    width: 7.5px;
    height: 7.5px;
}

.overflow-custom-onHover::-webkit-scrollbar-track,
.search-overflow-custom::-webkit-scrollbar-track,
.list-group-item-overflow::-webkit-scrollbar-track{
    background: transparent;
}
.overflow-custom-onHover::-webkit-scrollbar-thumb,
.search-overflow-custom::-webkit-scrollbar-thumb,
.list-group-item-overflow::-webkit-scrollbar-thumb{
    background: #5a6066;
    border-radius: 5px;
}
.overflow-custom-onHover::-webkit-scrollbar-corner,
.search-overflow-custom::-webkit-scrollbar-corner,
.list-group-item-overflow::-webkit-scrollbar-corner{
    background: transparent;
}
.overflow-custom-onHover::-webkit-scrollbar-thumb:hover,
.search-overflow-custom::-webkit-scrollbar-thumb:hover,
.list-group-item-overflow::-webkit-scrollbar-thumb:hover{
    background: #aaaaaa;
}

.search-overflow-custom a{
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap:break-word;
    white-space: nowrap;
}

.alarmFaultAnim{
    animation-name: alarmActive;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    /* animation-timing-function: cubic-bezier(1, 0, 0, 1); */
    animation-timing-function: ease-out;
}

.table-alarm tbody tr.unAck{
    /* border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important; */
    background:rgba(158, 198, 221,0.05);
}

.table-alarm tbody tr.unAck:hover{
    /* border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important; */
    background:rgba(217, 241, 255,0.15);
}

.table-alarm tbody tr.unAck .AlarmAckDot::before{
content: "•";
margin-right: -25px;
margin-left: 14px;
font-size:115%;
line-height:0px;
color: #548adb ;
}


.table-alarm tbody tr td.border-bottom-dark{
    /* border-width: 2px; */
    border-top: none !important;
}

/* className={val.prop.ack ? '' : 'unAck'} */

.table-alarm tbody tr.normal{
    animation-name: alarmNormal;
    animation-duration: 1s;
}

.sizeFromZero{
    animation-name: sizeFromZero;
    animation-duration: 0.35s;
    animation-timing-function: ease;
}

@keyframes sizeFromZero{
    from{
        transform: translate(-50%,-50%) scale(0,0) ;
    }
}
@keyframes alarmActive{
    from{
        background-color: rgba(238, 82, 93,0.25);
        /* opacity: 0; */
    }
}

@keyframes alarmNormal{
    from{
        background-color: rgba(63, 74, 87, 0.5);
        opacity: 0;
    }
    to{
    opacity: 1;
    }
}


.modal-content {
    background-color: #22252a;
    /* background-color: #2d3036; */
    border: 0 solid rgba(0, 0, 0, 0.2);
  }
  

  .btn-custom{
    background: rgba(84, 138, 219,0.25) !important;
    color: #5e8ae4 !important;      
  }
  
  .fixedTimeSel:hover,
  .fixedTimeSel.active,
  .optionTimeSel:hover,
  .optionTimeSel.active{
    color: #ecf0f3 !important;
    background: #548adb !important;
  }

  
  .btn-custom:hover,
  .btn-custom:focus{
    background: rgba(84, 138, 219,0.15) !important;
    color: hsl(220, 71%, 70%) !important;      
  }

  #maps-search-item a.dropdown-item{
      display: block;
  }
  
  #maps-search-item a.dropdown-item.hide{
    display: none;
}


#maps-search-item-modal div{
    display: block;
}

#maps-search-item-modal div.hide{
  display: none;
}

#maps-search{
    
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap:break-word;
    white-space: nowrap;
}

.loadingDotAnim::after{
    content: " ";
    animation: dotAnim 2.5s ease 0s infinite;
}

@keyframes dotAnim {
    0%{
        content: " ";
    }
    25%{
        content: " .";
    }
    50%{
        content: " ..";
    }    
    75%{
        content: " ...";
    }  
    100%{
        content: " ";
    }  
}

.custom-checkBox{
    color: transparent;
    border: solid 2px rgba(255,255,255,0.15);
    border-radius: 3.5px;
    width: calc(100% - 1px);
    font-size: 75%;
}

.custom-checkBox:hover{
    border: solid 2px rgba(255,255,255,0.4);
}

.custom-checkBox.active{
    /* color: #22252a ; */
    color: #548adb ;
    background: transparent;
    border: solid 2px transparent;
}


.table-alarm tbody tr.selected{
    background: rgba(108, 140, 170, 0.2) !important;
}

.overflowX-custom{
        overflow: hidden;
}

@media (max-width: 576px) { 
/* #alarmTable-wrapper{
    width: 580px !important;
}

#alarmWrapper{
    overflow-x: scroll;
} */
}

@media (max-width: 1200px) { 

    .tableRowResponsiveCustom-1{
width: calc(40% - 10px) !important;
    }
    .tableRowResponsiveCustom-2{
        width: 25% !important;
            }
}

.activeMenu{
    display: none;
}

.page-arrow-active{
    box-shadow: 0px 0px 0px 0px transparent;
    transition: all 0.15s ease;
}
.page-arrow-active:hover{
    color: #ecf0f3;
    box-shadow: 0px 0px 0px 3px #3a3e45;
    background: #3a3e45;
}

.card-hoverable-inactive{
    background: hsl(220, 9%, 20%);
    filter:brightness(65%) opacity(0.75);
    box-shadow: 0px 5px 15px rgba(30, 33, 36, 0.5);
    transition: all 0.25s ease-out,background 0.15s ease-out;
}

.card-hoverable-inactive:hover {
    background: #22252a;
    filter: brightness(100%) opacity(0.5);
    margin-top: -10px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.15) !important;
}


.card-hoverable-invert{
    background: #22252a;
    transition: all 0.15s ease-out;
}

.card-hoverable-invert:hover {
    background: #131518;
    cursor: pointer;
}

.card-invert{
    /* background: rgba(0, 0, 0, 0.25) !important; */
    background: rgba(18, 20, 23,0.35);
    transition: all 0.15s ease-out;
}

.loading-content{
    background: hsl(220, 9%, 20%);
    /* filter:brightness(85%); */
    animation-name: loadingContent;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1,0,0,1);
    border-radius: 10px;
}

@keyframes loadingContent{
    from{
        opacity: 0;
    }
    to{
        opacity: 0.8;
    }
}

.carousel-indicators span {
    box-sizing: content-box;
    flex: 0 1 auto;
    height: 7.5px;
    margin-right: 3px;
    margin-left: 3px;
    cursor: pointer;
    color: hsl(220, 3%, 35%) !important;
    /* opacity: 0.5; */
    font-size: 125%;
    transition: all 0.6s ease;
  }
  
.carousel-indicators .active {
    color: #548adb !important;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators span {
      transition: none;
    }
  }

  
.carousel-control-next,
.carousel-control-prev {
  width: 20px !important;
}

.sort-hover{
    transition: all 0.25s ease;
}

.sort-hover:hover{
    /* background: red; */
    cursor: pointer;
    color: #a3a6ac;
    background: #2d3036;
}

.sort-hover:hover .text-muted{
    /* background: red; */
    color: #a3a6ac !important;
}

.sort-hover.active{
    background: rgba(84, 138, 219,0.2) ;
    transition: all 0.25s ease;
}
.sort-hover.active .text-muted{
    color: hsl(219, 74%, 56%) !important;
}

/* .sort-hover.active:hover{
    background: rgba(107, 149, 212, 0.2) ;
}
.sort-hover.active:hover .text-muted{
    color: hsl(219, 63%, 59%) !important;
} */



.search-custom{
    color: #6c757d !important;
    border: 1px solid transparent !important; 
    background-color: rgba(0,0,0,0.1) !important;
    border: 1px solid rgba(150,150,150,0.15) !important; 
    transition: all 0.25s ease;
}

.search-custom:focus{
    color: #c6cbcf !important;
}

.btn-search-custom{
    padding: 0.07rem 0.5rem;
    /* style={{marginTop:'-2.5px',marginBottom:'-2.5px', marginLeft:'-40px'}} */
    margin: -3.5px 0px -1.5px -35px;
    /* opacity: 0; */
    /* pointer-events: none; */
    pointer-events: auto;
    transition: all 0.15s ease,opacity 0.5s ease;
}


.btn-search-custom:hover{
    color:#ecf0f3 !important;
    /* background: #548adb !important; */
}

.search-custom:focus + .btn-search-custom{
/* opacity: 1; */
pointer-events: auto;
}

.searchHideActive.active {
    display: none !important;
}

.searchHideActive ~ .hideOnSearch,
.searchHideActive{
    display: inline-block !important;
    animation: opafrom0 0.5s ease;
}

.searchHideActive.active ~ .hideOnSearch,
.searchHideActive.active i{
    display: none !important;
}

.searchHideActive ~ .showOnSearch{
    display:none !important;
}

.searchHideActive.active ~ .showOnSearch{
    display:inline-block !important;
    animation: opafrom0 0.5s ease;
}

@keyframes opafrom0{
    from{
        /* transform: translate(-50%,0%) scale(0,1); */
        opacity: 0;
    } 
}

.close-custom{
    padding: 0.07rem 0.5rem;
    margin: -3.5px 0px -1.5px -35px;
}

.close-custom:hover{
 color: #ee525d !important;
}


.close-custom:hover{
    color: #ee525d !important;
   }
   
   
   @media (max-width: 576px) { 
       .searchHideActive {
           display: none !important;
       }
       
   .search-custom {
    width: 50% !important;
   }
   }


   .text-wrap-custom{
       overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap;
       word-wrap: break-word;
       width: 100% !important;
       display: inline-block;
   }
   
   .link-custom{
       cursor: pointer;
       }

   .link-custom:hover{
background: #22252a;
filter: brightness(150%);
padding: 1px 7.5px;
margin: -1.5px -7.5px 3px -7.5px !important;
   }

   .modal-body .overflow-custom .card:last-child{
       margin-bottom: 0px !important;
   }

   .relocateBtn{
    background: rgba(29,32,38,0.75);
    transition: all 0.25s ease;
   }
   
   .relocateBtn:hover{
    background: rgba(10,11,13,0.75);
       filter: brightness(150%);
   }
   .text-custom-table{
   color:#adb5bd !important;
}

.border-custom-dark{
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.input-group-text-custom {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.7rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255,255,255,0.65);
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 0.2rem;
}

.inputActionBtn{
    margin-left: -37.5px;
    cursor: pointer !important;
    z-index: 99;
}

.inputActionBtnCopy{
    margin-left: -67.5px;
    cursor: pointer !important;
    z-index: 99;
}

.form-control-login{
    box-shadow: none;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.15);
    background-color: transparent;
    margin-left: -35px;
    padding:0px 35px;
    height: 40px;
    color: rgba(255,255,255,0.85) !important;
    font-weight: 500;
    transition: all 0.5s ease-out;
}

.form-control-login:focus{
    box-shadow: none;
    border-bottom: 2px solid rgba(56, 160, 201, 0.5);
    background-color: transparent;
    color: rgba(255,255,255,0.85) !important;
}


.form-control-login::-webkit-input-placeholder { 
    color: rgba(255,255,255,0.3) !important;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .form-control-login:-ms-input-placeholder { 
    color: rgba(255,255,255,0.3) !important;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .form-control-login::placeholder {
    color: rgba(255,255,255,0.4) !important;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .form-control-login:-webkit-autofill,
.form-control-login:-webkit-autofill:hover, 
.form-control-login:-webkit-autofill:focus{
    border-radius: 5px !important;
    -webkit-box-shadow: 0 0 0px 1000px rgba(50,50,50,0.7) inset;
    opacity: 0.75 !important;
    border-bottom: 2px solid transparent !important;
    -webkit-text-fill-color: rgba(255,255,255,1) !important;
}

  .loginLink{
    font-size: 0.8rem;
    color: rgba(255,255,255,0.4) !important;
  }

  .loginLink:hover{
    color: rgba(255,255,255,0.5) !important;
  }

  .btn-snapX-login-clean{
    background: rgba(255,255,255,0.2) !important;
    color: rgba(255,255,255,0.8) !important;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .btn-snapX-login-clean:hover,
  .btn-snapX-login-clean:focus{
    background: rgba(255,255,255,0.35) !important;
    color: rgba(255,255,255,1) !important;
    box-shadow: none;
  }

  .btn-line-login-clean{
    background: rgba(0, 195, 0,0.5);
    color: #fff;
  }

  .btn-line-login-clean:hover,
  .btn-line-login-clean:focus{
    box-shadow: none;
    background: rgba(0, 179, 0,0.85);
  }

  .loginCard-wrapper{
    width: 500px;
  }
  
@media (max-width: 768px) {
    .loginCard-wrapper{
      width: 400px;
    }
}


.submenu:not(#submenuCollapse){
    width: 200px !important;
    height: calc(100vh - 225px) !important;
}

.submenu .sidebar-nav .sidebar-item{
    border-left: solid 2.5px transparent;
    transition: all 0.25s ease-out;
}

.submenu .sidebar-nav .sidebar-item:hover a{
    color: hsl(207, 10%, 70%) !important;
}

.submenu .sidebar-nav .sidebar-item.active{
    border-color:#2a85b9;
}

.submenu .sidebar-nav .sidebar-item.active a{
    color: #e0e6eb !important;
}

.subnav .nav .nav-item,
.subnav .nav .nav-link{
    border-bottom: solid 2.5px transparent;
    transition: all 0.25s ease-out;
    outline: none;
}

.subnav .nav .nav-item:hover a,
.subnav .nav .nav-link:hover span{
    color: hsl(207, 10%, 70%) !important;
}

.subnav .nav .nav-item.active,
.subnav .nav .nav-link.active{
    border-color:#2a85b9;
}

.subnav .nav .nav-item.active a,
.subnav .nav .nav-link.active span{
    color: #e0e6eb !important;
}


.toggle-switch-custom{
    background: hsl(207, 10%, 26%);
    outline: none !important;
    width: 35px;
    height: 10px;
    border: none !important;
    vertical-align: middle;
    padding: 0px;
    transition: all 0.25s ease,background 0.5s ease ,box-shadow 0.5s ease-out;
}

.toggle-switch-custom .knob{
    background:hsl(202, 5%, 50%);
    width: 20px;
    height: 20px;
    margin-top: -5px;
    border-radius: 50%;
    pointer-events: none;
    transition: all 0.25s ease;
    position: relative;
    left: 0%;
    transform: translate(0%,0%);
}


.toggle-switch-custom.active{
    background: hsl(207, 10%, 26%);
    outline: none !important;
    width: 35px;
    height: 10px;
    border: none !important;
    vertical-align: middle;
    padding: 0px;
}

.toggle-switch-custom.active .knob{
    background:#3798d2;
    width: 20px;
    height: 20px;
    margin-top: -5px;
    border-radius: 50%;
    left: 100%;
    transform: translate(-100%,0%);
    transition: all 0.25s ease,background 0.5s ease ,box-shadow 0.5s ease-out;
}

.toggle-switch-custom:active .knob{
    box-shadow: 0px 0px 0px 15px rgb(55, 152, 210,0.15);
    transition: box-shadow 0.05s ease-out;
}

.header-menu-icon-rotate{
    transition: all 0.5s ease;
}


input[type="text"].text-input-custom{
    color: #c6cbcf;
    border: 1px solid rgb(42, 45, 52);
    background-color: rgba(5,7,15,0.1) !important;
}

input[type="text"].form-control-custom,
input[type="password"].form-control-custom{
    box-shadow: none;
    border: none;
    border-bottom: 2px solid rgba(255,255,255,0.15);
    background-color: transparent;
    color: rgba(255,255,255,0.85) !important;
    font-weight: 500;
    transition: all 0.5s ease-out;
}

input[type="text"].form-control-custom.filled,
input[type="password"].form-control-custom.filled{
    border-bottom: 2px solid rgba(255,255,255,0.35);
}

input[type="text"].form-control-custom:focus,
input[type="password"].form-control-custom:focus{
    box-shadow: none;
    border-bottom: 2px solid rgba(56, 160, 201, 0.5);
    background-color: transparent;
    color: rgba(255,255,255,0.85) !important;
}



input[type="password"].form-control-custom::-webkit-input-placeholder { 
    /* color: rgba(108, 117, 125,0.75) !important; */
    color: rgba(235,240,255,0.2) !important;
    font-size: 0.8rem;
    font-weight: 400;
  }
  
  input[type="password"].form-control-custom:-ms-input-placeholder { 
    /* color: rgba(108, 117, 125,0.75) !important; */
    color: rgba(235,240,255,0.2) !important;
    font-size: 0.8rem;
    font-weight: 400;
  }
  
  input[type="password"].form-control-custom::placeholder {
    /* color: rgba(108, 117, 125,0.75) !important; */
    color: rgba(235,240,255,0.2) !important;
    font-size: 0.8rem;
    font-weight: 400;
  }

  
@keyframes opaheightfrom0{
    from{
        opacity: 0;
    } 
}

.btn-cancel:hover{
text-decoration: underline;
filter: brightness(115%);
}

.btn-custom-success{
    background: rgba(33, 212, 102, 0.25) !important;
    color: #1ae475 !important;  
}

.text-custom-enable{
    /* background: rgba(33, 212, 102, 0.25) !important; */
    color: #3798d2 !important;  
}

#accountLineShow{
    max-width: 300px;
}
@media (max-width: 500px) {
    
    #accountLineShow{
        max-width: calc(100vw - 180px);
    }
    
     }
@media (max-width: 400px) {

#accountLineShow span.text1,
#accountLineShow i{
    display: none;
}

 }


 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
 
 /* Firefox */
 input[type=number] {
   -moz-appearance: textfield;
 }

 .text-custom-light.hover-current-color:hover{
    color: #a3a6ac;
    filter: brightness(125%);
 }

 .btn-cancel{
    background-color: transparent;
    border: solid 2px #c93c3c;
    color: #e45050;
    transition: none;
 }
 
 .btn-cancel:hover,
 .btn-cancel:target{
    background-color: #c93c3c;
    border: solid 2px #c93c3c;
    color: #22252a;
    text-decoration: none;
    transition: none;
 }
 
 .btn-cancel-1{
    background-color: transparent;
    border: solid 1px #c93c3c;
    color: #e45050;
    transition: none;
 }
 
 .btn-cancel-1:hover,
 .btn-cancel-1:target{
    background-color: #c93c3c;
    border: solid 1px #c93c3c;
    color: #22252a;
    text-decoration: none;
    transition: none;
 }

 .editName{
    color: #454752;
 }
 
 .editName svg{
    pointer-events: none;
}

.editName:hover{
    color: #adb5bd;
}

input.nameEditor{
    margin: -5px 0;
}

input.nameEditor:focus{
    color: #fff;
    font-weight: medium;
}

a.cancel-text{
    color: #6c757d;
}

.cancel-text:hover,
.cancel-text:active{
    color: #adb5bd;
}

.btn-custom.savename{
    margin: -5px 0;
}

.btn-custom.savename:hover{
    background: rgba(84, 138, 219,0.5) !important;
    color: hsl(220, 100%, 70%) !important;
}